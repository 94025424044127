var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"barraCrud p-0 p-md-3"},[(_vm.showTitle)?_c('div',{staticClass:"titulo"},[(
          (_vm.menu_ativo && _vm.menu_ativo.iconType == 'png') ||
          (_vm.iconeCustomizado && _vm.iconeCustomizado.includes('png'))
        )?_c('img',{attrs:{"src":require(`@/assets/img/icones/menu/${
            _vm.iconeCustomizado != null && _vm.iconeCustomizado.length > 0
              ? _vm.iconeCustomizado
              : _vm.menu_ativo.icon
          }`)}}):_vm._e(),(
          (_vm.menu_ativo && _vm.menu_ativo.iconType == 'fa') ||
          (_vm.iconeCustomizado && _vm.iconeCustomizado.includes('fa'))
        )?_c('i',{staticClass:"mr-3",class:_vm.iconeCustomizado != null && _vm.iconeCustomizado.length > 0
            ? _vm.iconeCustomizado
            : _vm.menu_ativo.icon,staticStyle:{"font-size":"40px"}}):_vm._e(),(_vm.menu_ativo || _vm.nomeCustomizado)?_c('h3',[_vm._v(" "+_vm._s(" " + (_vm.nomeCustomizado ? _vm.nomeCustomizado : _vm.menu_ativo.nome))+" ")]):_vm._e()]):_vm._e(),(_vm.busca)?_c('Buscar',{attrs:{"onFiltrar":_vm.onFiltrar,"buscaAberta":_vm.buscaAberta}}):_vm._e(),_c('div',{staticClass:"bread"},[(_vm.breadcrumb)?_c('b-breadcrumb',{staticClass:"brd",attrs:{"items":_vm.breads}}):_vm._e(),(_vm.botaoNovo)?_c('b-button',{attrs:{"block":_vm.isMobile,"variant":"primary"},on:{"click":_vm.onNovo}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add. "+_vm._s(_vm.nomeCustomizado ? _vm.nomeCustomizado : _vm.menu_ativo ? _vm.menu_ativo.nome : ""))]):_vm._e(),(_vm.data)?_c('div',{staticClass:"mt-2"},[_c('v-date-picker',{attrs:{"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('div',{staticClass:"flex justify-center items-center"},[_c('input',_vm._g({staticClass:"form-control",domProps:{"value":`${inputValue.start} - ${inputValue.end}`}},inputEvents.start))])]}}],null,false,1489337553),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1):_vm._e()],1),(_vm.empresa)?_c('div',{staticStyle:{"min-width":"200px","display":"block"}},[_c('v-select',{staticStyle:{"max-width":"500px","display":"block"},attrs:{"multiple":"","options":_vm.empresas,"label":"nome"},model:{value:(_vm.empresaSelecionada),callback:function ($$v) {_vm.empresaSelecionada=$$v},expression:"empresaSelecionada"}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }