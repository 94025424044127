<template>
  <div>
    <div class="barraCrud p-0 p-md-3">
      <div class="titulo" v-if="showTitle">
        <img
          :src="
            require(`@/assets/img/icones/menu/${
              iconeCustomizado != null && iconeCustomizado.length > 0
                ? iconeCustomizado
                : menu_ativo.icon
            }`)
          "
          v-if="
            (menu_ativo && menu_ativo.iconType == 'png') ||
            (iconeCustomizado && iconeCustomizado.includes('png'))
          "
        />
        <i
          :class="
            iconeCustomizado != null && iconeCustomizado.length > 0
              ? iconeCustomizado
              : menu_ativo.icon
          "
          v-if="
            (menu_ativo && menu_ativo.iconType == 'fa') ||
            (iconeCustomizado && iconeCustomizado.includes('fa'))
          "
          style="font-size: 40px"
          class="mr-3"
        ></i>

        <h3 v-if="menu_ativo || nomeCustomizado">
          {{ " " + (nomeCustomizado ? nomeCustomizado : menu_ativo.nome) }}
        </h3>
      </div>
      <Buscar :onFiltrar="onFiltrar" :buscaAberta="buscaAberta" v-if="busca" />
      <div class="bread">
        <b-breadcrumb
          class="brd"
          v-if="breadcrumb"
          :items="breads"
        ></b-breadcrumb>
        <b-button v-if="botaoNovo" :block="isMobile" variant="primary" @click="onNovo"
          ><i class="fa fa-plus"></i> Add.
          {{
            nomeCustomizado
              ? nomeCustomizado
              : menu_ativo
              ? menu_ativo.nome
              : ""
          }}</b-button
        >
        <div v-if="data" style="" class="mt-2">
          <v-date-picker v-model="dateRange" is-range>
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="`${inputValue.start} - ${inputValue.end}`"
                  v-on="inputEvents.start"
                  class="form-control"
                />
              </div>
            </template>
          </v-date-picker>
        </div>
      </div>
      <div v-if="empresa" style="min-width: 200px; display: block">
        <v-select
          style="max-width: 500px; display: block"
          multiple
          v-model="empresaSelecionada"
          :options="empresas"
          label="nome"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Buscar from "../common/Buscar.vue";
export default {
  name: `BarraCrudComp`,
  props: {
    onFiltrar: Function,
    botaoNovo: Boolean,
    onNovo: Function,
    iconeCustomizado: { type: String, default: "" },
    nomeCustomizado: { type: String, default: "" },
    breadcrumb: { type: Boolean, default: true },
    busca: { type: Boolean, default: true },
    data: { type: Boolean, default: false },
    empresa: { type: Boolean, default: false },
    buscaAberta: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    title:{
      type: String,
      default: ""
    }
  },
  components: {
    Buscar,
  },
  data() {
    return {
      menu_ativo: null,
      breads: [],
      dateRange: {
        start: new Date(),
        end: new Date(),
      },
      empresas: [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ],
      empresaSelecionada: [],
      isMobile: false,
    };
  },
  watch: {
    dateRange: function (val) {
      this.$emit("onFitlrarData", val);
    },
  },
  mounted() {
    // console.log(this.empresas);
    let mnus = this.$store.state.menu.reduce((ret, item) => {
      if (item.children && item.children.length > 0) {
        item.children = item.children.map((itt) => {
          return { pai: item, ...itt };
        });
        ret.push(...item.children);
      }
      ret.push(item);
      return ret;
    }, []);
    this.menu_ativo = mnus.find(
      (x) => x.link == this.$route.path || x.link == this.$route.path.substr(1)
    );
    // console.log("MENUUU", this.menu_ativo);
    if (this.menu_ativo) {
      if (this.menu_ativo.pai) {
        this.breads.push({
          text: this.menu_ativo.pai.nome,
          href: this.menu_ativo.pai.link,
        });
      }
      this.breads.push({
        text: this.menu_ativo.nome,
        href: this.menu_ativo.link,
      });
    }

    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 575) {
        this.buscaAberta = true;
        this.isMobile = true;
      } else {
        this.buscaAberta = false;
        this.isMobile = false;
      }
    },
  },
};
</script>

<style></style>
